"use strict";

export function initInScope ($scope) {

    /* toggle checkbox
    $scope.find('#billingAddress').on('hidden.bs.collapse', function () {
        $scope.find('#billingAddress .js-billing-checkbox input').prop('checked',false);
    }); */

    $scope.find('#billingAddress').on('shown.bs.collapse', function () {
        $scope.find('#billingAddress .js-billing-checkbox input').prop('checked','checked');
    });


    /* close if checkbox not checked */
    $scope.find('.js-billing-checkbox input').on('change',function (e) {
        $scope.find('#billingAddress').collapse('hide');
    });
}
